body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.modern-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.modern-table th,
.modern-table td {
  padding: 15px;
  background-color: #fff;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.modern-table th {
  background-color: #f8f8f8;
  font-weight: bold;
  color: #333;
}

.modern-table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.modern-table tbody tr:hover {
  background-color: #eaeaea;
}